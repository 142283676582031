@import '@src/ui/sass/variables';
@import '@src/ui/sass/media-queries';

.category {
  &__jobTypes {
    margin-top: $spacing-5;
    margin-bottom: auto;

    &__heading {
      margin: $spacing-3 0 $spacing-3;
      display: block;
      font-weight: $font-weight-strong;
    }

    &__content {
      padding-left: $spacing-5;
      padding-right: $spacing-5;
      &--showDesignVariant {
        padding: 0;
        @include for-responsive-device-up {
          width: 400px;
          margin: 0 auto;
        }
      }
    }
  }
}
